<template>
  <section class="main">

  </section>
</template>

<script>
export default {
  name: "AdminPage"
}
</script>

<style scoped>

</style>