
import { defineComponent } from 'vue';
import NavBar from "@/components/NavBar.vue";
import DarkmodeSwitcher from "@/components/miniComponents/DarkmodeSwitcher.vue";

export default defineComponent({
  name: 'App',
  components: {
    DarkmodeSwitcher,
    NavBar
  },
  metaInfo(){
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      }
    }
  },
  data(){
    return{
      isDarkMode: false
    }
  },
  mounted() {
    //sets the user preferred theme used system-wide or from browser settings
    this.isDarkMode = this.hasDarkPreference();
    document.documentElement.setAttribute('dark-mode', this.isDarkMode.toString())
  },
  methods: {
    hasDarkPreference(){
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    },
    toggleDarkMode(){
      this.isDarkMode = !this.isDarkMode;
      document.documentElement.setAttribute('dark-mode', this.isDarkMode.toString())
    }
  }
});
