import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DarkmodeSwitcher = _resolveComponent("DarkmodeSwitcher")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_NavBar, {
      onToggleDarkmode: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleDarkMode())),
      "is-dark-mode": _ctx.isDarkMode
    }, null, 8, ["is-dark-mode"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(( { Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, { exclude: "WorkComp" }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ], 1024))
      ]),
      _: 1
    }),
    _createVNode(_component_DarkmodeSwitcher, {
      "is-dark-mode": _ctx.isDarkMode,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDarkMode())),
      id: "darkmode-toggle-floating"
    }, null, 8, ["is-dark-mode"])
  ]))
}